<template>
  <div class="dialogMask">
    <div class="dialog">
      <div class="tit">
        <span>{{ dialogTitle }}</span>
        <img
          v-if="showClose"
          src="../assets/imgs/closePage.png"
          @click="handleCancel(true)"
          alt=""
        />
      </div>
      <div class="content">{{ dialogContent }}</div>
      <div class="bottom">
        <span @click="handleCancel(false)">{{ dialogCancel }}</span>
        <span @click="handleOk">{{ dialogEnter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialog",

  data() {
    return {};
  },

  props: {
    showClose: Boolean,
    // showClose: {
    //   type: Boolean,
    //   default: true,
    // },
    dialogContent: {
      type: String,
      default: "确定提交吗？",
    },
    dialogTitle: {
      type: String,
      default: "提示",
    },
    dialogCancel: {
      type: String,
      default: "取消",
    },
    dialogEnter: {
      type: String,
      default: "确定",
    },
  },

  watch: {
    showClose(newValue) {
      console.log(newValue);
    },
  },

  // model: {
  //   showClose: true,
  // },

  mounted: function () {},

  created: function () {},

  methods: {
    handleOk() {
      //1取出不退库 2退库
      this.$emit("update:showClose", true);
      this.$emit("handleOk");
    },
    handleCancel(flag) {
      this.$emit("update:showClose", true); // 兼容有的页面要❌有的不要😂
      this.$emit("handleCancel", flag);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dialogMask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;
  .dialog {
    width: 100%;
    height: 3.76rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffffff;
    border-radius: 0.32rem 0.32rem 0px 0px;
    .tit {
      font-size: 0.4rem;
      color: #222222;
      text-align: center;
      margin: 0.4rem 0.36rem;
      font-weight: bold;
      position: relative;

      img {
        position: absolute;
        width: 0.48rem;
        height: 0.48rem;
        top: 0;
        right: 0;
        z-index: 2;
      }
    }
    .content {
      font-size: 0.32rem;
      color: #222222;
      text-align: center;
      padding: 0 0.25rem;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.48rem;
      span {
        width: 3.15rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        font-size: 0.28rem;
        &:first-child {
          background: #ecedf2;
          border-radius: 0.48rem;
          color: #000000;
          font-weight: 600;
        }
        &:last-child {
          background: #436eff;
          border-radius: 0.48rem;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
